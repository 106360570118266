import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { asaasStore, mainStore } from '@/store';
let AdesaoStep2 = class AdesaoStep2 extends Vue {
    constructor() {
        super(...arguments);
        this.companyTypeOptions = [
            { text: 'Limitada', value: 'LIMITED' },
            { text: 'Individual', value: 'INDIVIDUAL' }
        ];
        this.incomeValue = null;
        this.companyType = null;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async criarConta() {
        await asaasStore.subcontaCriar({
            incomeValue: this.incomeValue,
            companyType: this.companyType
        });
        this.$router.push('/main/FinanceiroDrivePay/AdesaoStep3');
    }
};
AdesaoStep2 = __decorate([
    Component({
        components: {}
    })
], AdesaoStep2);
export default AdesaoStep2;
