import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AdesaoStepQueroAderir from './TabDrivePay/AdesaoStepQueroAderir.vue';
import AdesaoStepAguardandoAprovacao from './TabDrivePay/AdesaoStepAguardandoAprovacao.vue';
import AdesaoStepContaAprovada from './TabDrivePay/AdesaoStepContaAprovada.vue';
import { mainStore, asaasStore } from '@/store';
let TabDrivePay = class TabDrivePay extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.dadosSubconta = null;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async mounted() {
        this.loading = true;
        this.dadosSubconta = await asaasStore.subcontaConsultar();
        this.loading = false;
    }
};
TabDrivePay = __decorate([
    Component({
        components: {
            AdesaoStepQueroAderir,
            AdesaoStepAguardandoAprovacao,
            AdesaoStepContaAprovada
        }
    })
], TabDrivePay);
export default TabDrivePay;
