import { __decorate } from "tslib";
import { Vue, Component, Watch } from 'vue-property-decorator';
import { alunoStore, companyConfiguracaoCursoDescontoStore, companyConfiguracaoDiaVencimentoParcelaStore, companyConfiguracaoMaterialParcelamentoStore, companyConfiguracaoNegociacaoMultaRescisoriaContratoStore, companyConfiguracaoNegociacaoStore, companyConfiguracaoStore, companyStore, cursoStore, financeiroCategoriaJurosDescontoStore, mainStore } from '@/utils/store-accessor';
import _ from 'lodash';
import SaveButton from '@/components/SaveButton.vue';
import DocumentEditorComponent from '@/components/DocumentEditorComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import FinanceiroCategoria from './FinanceiroCategoria.vue';
import TabDrivePay from './TabDrivePay.vue';
import ReajustarMaterialParcelamentoComponent from '@/components/ReajustarMaterialParcelamentoComponent.vue';
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
Validator.extend('quantidadeParcelas', {
    validate: async (value) => {
        if (!value || value <= 0) {
            return false;
        }
        return true;
    },
    getMessage: field => 'Quantidade de parcelas não pode ser (zero)'
});
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.content = [];
        this.loading = true;
        this.search = '';
        this.tab = 'precificacoes';
        this.templateDownload = '';
        this.templateImportacao = '';
        this.importHaveErrors = false;
        this.importFileHeader = { fields: [], comments: [] };
        this.importFile = null;
        this.templateOptions = ['Alunos'];
        this.verifyImportItems = [];
        this.allowMaterialParcelamentoEdit = false;
        this.allowCursoDescontoEdit = false;
        this.allowNegociacaoEdit = false;
        this.allowNegociacaoMultaRescisoriaEdit = false;
        this.allowJurosDescontoEdit = [];
        this.allowRecebimentoTaxaAvulsaEdit = false;
        this.allowRecebimentoMaterialDidaticoEdit = false;
        this.allowRecebimentoParcelaCursoEdit = false;
        this.allowRecebimentoTaxaReforcoEdit = false;
        this.allowRecebimentoTaxaReposicaoEdit = false;
        this.allowRecebimentoTaxaMatriculaEdit = false;
        this.allowMultaCancelamentoContratoEdit = false;
        this.tempMaterialParcelamento = null;
        this.tempCursoDesconto = null;
        this.tempNegociacao = null;
        this.tempNegociacaoMultaRescisoria = null;
        this.tempRecebimentoTaxaAvulsa = null;
        this.tempRecebimentoMaterialDidatico = null;
        this.tempRecebimentoParcelaCurso = null;
        this.tempRecebimentoTaxaReforco = null;
        this.tempRecebimentoTaxaReposicao = null;
        this.tempRecebimentoTaxaMatricula = null;
        this.tempMultaCancelamentoContrato = null;
        this.newMaterialParcelamento = false;
        this.newCursoDesconto = false;
        this.newDiaVencimentoParcelas = false;
        this.tempDiaVencimentoParcelas = null;
        this.allowDiaVencimentoParcelasEdit = false;
        this.newNegociacao = false;
        this.newNegociacaoMultaRescisoria = false;
        this.newRecebimentoTaxaAvulsa = false;
        this.newRecebimentoMaterialDidatico = false;
        this.newRecebimentoParcelaCurso = false;
        this.newRecebimentoTaxaReforco = false;
        this.newRecebimentoTaxaReposicao = false;
        this.newRecebimentoTaxaMatricula = false;
        this.newMultaCancelamentoContrato = false;
        this.aplicarMultaAteFinalContrato = false;
        this.isLoadingRestaurarModeloPadrao = false;
        this.dialogReajustarMaterialParcelamento = false;
        this.isLoadingRestaurarPadrao = false;
        this.tabDadosUnidadeData = null;
        this.parcelamentoMaterialHeaders = [
            { text: 'Quantidade de parcelas', value: 'numero_vezes' },
            { text: 'Valor Integral', value: 'valor_integral' }
        ];
        this.negociacaoHeaders = [
            { text: 'Quantidade de parcelas', value: 'numero_vezes' },
            { text: 'Desconto percentual máximo', value: 'desconto_percentual' },
            { text: 'Desconto valor máximo', value: 'desconto_valor' }
        ];
        this.cursoDescontoHeaders = [
            { text: 'Percentual de desconto', value: 'percentual' }
        ];
        this.diaVencimentoParcelas = [{ text: 'Dia do mês', value: 'dia' }];
        this.headers = [
            { text: 'Etapa do curso', value: 'etapa' },
            { text: 'Preço regular', value: 'preco_regular' },
            { text: 'Preço vip', value: 'preco_vip' },
            { text: 'Preço no intensivo', value: 'preco_intensivo' },
            { text: 'Preço no executive', value: 'preco_executive' },
            { text: 'Preço da aula avulsa', value: 'preco_aula_avulsa' },
            {
                text: 'Percentual da taxa de recisão',
                value: 'percentual_taxa_rescisao'
            },
            { text: 'Preço da matrícula', value: 'preco_matricula' },
            { text: 'Preço da aula de reposição', value: 'preco_aula_reposicao' }
        ];
        this.financeiroCategoriaJurosDescontoHeaders = [
            { text: 'Vigência - Início', value: 'vigencia_inicio' },
            { text: 'Vigência - Fim', value: 'vigencia_fim' },
            {
                text: 'Perda de desconto - Quantidade de dias inicial',
                value: 'desconto_perda_quantidade_dia_vencido_inicio'
            },
            {
                text: 'Perda de desconto - Quantidade de dias final',
                value: 'desconto_perda_quantidade_dia_vencido_fim'
            },
            {
                text: 'Perda de desconto - Percentual a ser removido',
                value: 'desconto_perda_percentual'
            },
            {
                text: 'Juros ao mês (%)',
                value: 'juros_percentual'
            },
            {
                text: 'Multa (%)',
                value: 'multa_percentual'
            }
        ];
        this.financeiroCategoriaJurosDesconto = [];
        this.financeiroCategoriasJurosDesconto103 = [];
        this.financeiroCategoriasJurosDesconto104 = [];
        this.financeiroCategoriasJurosDesconto105 = [];
        this.financeiroCategoriasJurosDesconto106 = [];
        this.financeiroCategoriasJurosDesconto107 = [];
        this.financeiroCategoriasJurosDesconto108 = [];
        this.financeiroCategoriasJurosDesconto109 = [];
    }
    get companyConfiguracaoCursoDesconto() {
        return companyConfiguracaoCursoDescontoStore.companyConfiguracaoCursoDescontos;
    }
    get companyConfiguracaoDiaVencimentoParcelas() {
        return companyConfiguracaoDiaVencimentoParcelaStore.companyConfiguracaoDiaVencimentoParcelas;
    }
    get companyConfiguracaoNegociacoes() {
        return companyConfiguracaoNegociacaoStore.companyConfiguracaoNegociacoes;
    }
    get companyConfiguracaoNegociacoesMultaRescisoria() {
        return companyConfiguracaoNegociacaoMultaRescisoriaContratoStore.companyConfiguracaoNegociacoesMultaRescisoriaContrato;
    }
    get companyConfiguracaoMaterialParcelamento() {
        return companyConfiguracaoMaterialParcelamentoStore.companyConfiguracaoMaterialParcelamentos;
    }
    get entityObject() {
        return companyConfiguracaoStore.companyConfiguracoes
            ? companyConfiguracaoStore.companyConfiguracoes
            : {};
    }
    get headersImport() {
        const { fields, comments } = this.importFileHeader;
        const headers = [];
        for (let i = 0; i < fields.length; i++) {
            headers.push({
                text: comments[i],
                sortable: true,
                value: fields[i],
                align: 'left'
            });
        }
        return headers;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get cursosEtapas() {
        return cursoStore.cursosEtapas;
    }
    async onAplicarMultaAteFinalContrato(val) {
        if (val) {
            // @ts-ignore
            this.entityObject.rescisao_quantidade_meses_aplicar_multa = 0;
        }
    }
    async onRescisao_quantidade_meses_aplicar_multa(val) {
        this.aplicarMultaAteFinalContrato = val === 0;
    }
    async restaurarPadroesConfiguracoes() {
        this.isLoadingRestaurarPadrao = true;
        await companyConfiguracaoStore.companyConfiguracaoRestaurarPadrao('outros');
        this.isLoadingRestaurarPadrao = false;
    }
    async restaurarFinanceiroCategoriaJurosDesconto103() {
        this.financeiroCategoriasJurosDesconto103 = [
            {
                // @ts-ignore
                financeiro_categoria_id: this.getFinanceiroCategoriaTaxaAvulsa103(),
                edit: false,
                vigencia_inicio: null,
                vigencia_fim: null,
                desconto_perda_quantidade_dia_vencido_inicio: '',
                juros_percentual: '2',
                multa_percentual: '5'
            }
        ];
    }
    async restaurarFinanceiroCategoriaJurosDesconto104() {
        this.financeiroCategoriasJurosDesconto104 = [
            {
                financeiro_categoria_id: 
                // @ts-ignore
                this.getFinanceiroCategoriaMaterialDidatico104(),
                edit: false,
                vigencia_inicio: null,
                vigencia_fim: null,
                desconto_perda_quantidade_dia_vencido_inicio: '',
                juros_percentual: '2',
                multa_percentual: '5'
            }
        ];
    }
    async restaurarFinanceiroCategoriaJurosDesconto105() {
        this.financeiroCategoriasJurosDesconto105 = [
            {
                // @ts-ignore
                financeiro_categoria_id: this.getFinanceiroCategoriaCurso105(),
                edit: false,
                vigencia_inicio: null,
                vigencia_fim: null,
                desconto_perda_quantidade_dia_vencido_inicio: 16,
                desconto_perda_quantidade_dia_vencido_fim: null,
                juros_percentual: null,
                multa_percentual: null,
                desconto_perda_percentual: 100
            },
            {
                // @ts-ignore
                financeiro_categoria_id: this.getFinanceiroCategoriaCurso105(),
                edit: false,
                vigencia_inicio: null,
                vigencia_fim: null,
                desconto_perda_quantidade_dia_vencido_inicio: 4,
                desconto_perda_quantidade_dia_vencido_fim: 15,
                juros_percentual: null,
                multa_percentual: null,
                desconto_perda_percentual: 20
            },
            {
                // @ts-ignore
                financeiro_categoria_id: this.getFinanceiroCategoriaCurso105(),
                edit: false,
                vigencia_inicio: null,
                vigencia_fim: null,
                desconto_perda_quantidade_dia_vencido_inicio: 1,
                desconto_perda_quantidade_dia_vencido_fim: 3,
                juros_percentual: null,
                multa_percentual: null,
                desconto_perda_percentual: 10
            }
        ];
    }
    async restaurarFinanceiroCategoriaJurosDesconto106() {
        this.financeiroCategoriasJurosDesconto106 = [
            {
                // @ts-ignore
                financeiro_categoria_id: this.getFinanceiroCategoriaTaxaReforco106(),
                edit: false,
                vigencia_inicio: null,
                vigencia_fim: null,
                desconto_perda_quantidade_dia_vencido_inicio: '',
                juros_percentual: '2',
                multa_percentual: '5'
            }
        ];
    }
    async restaurarFinanceiroCategoriaJurosDesconto107() {
        this.financeiroCategoriasJurosDesconto107 = [
            {
                financeiro_categoria_id: 
                // @ts-ignore
                this.getFinanceiroCategoriaTaxaReposicaoAula107(),
                edit: false,
                vigencia_inicio: null,
                vigencia_fim: null,
                desconto_perda_quantidade_dia_vencido_inicio: '',
                juros_percentual: '2',
                multa_percentual: '5'
            }
        ];
    }
    async restaurarFinanceiroCategoriaJurosDesconto108() {
        this.financeiroCategoriasJurosDesconto108 = [
            {
                // @ts-ignore
                financeiro_categoria_id: this.getFinanceiroCategoriaTaxaMatricula108(),
                edit: false,
                vigencia_inicio: null,
                vigencia_fim: null,
                desconto_perda_quantidade_dia_vencido_inicio: '',
                juros_percentual: '2',
                multa_percentual: '5'
            }
        ];
    }
    async restaurarFinanceiroCategoriaJurosDesconto109() {
        this.financeiroCategoriasJurosDesconto109 = [
            {
                financeiro_categoria_id: 
                // @ts-ignore
                this.getFinanceiroCategoriaRescisaoContratual109(),
                edit: false,
                vigencia_inicio: null,
                vigencia_fim: null,
                desconto_perda_quantidade_dia_vencido_inicio: '',
                juros_percentual: '2',
                multa_percentual: '5'
            }
        ];
    }
    restaurarContratoPadrao() {
        this.$swal({
            title: 'Confirma a alteração do modelo do contrato?',
            text: 'Os contrato já gerados não serão afetados',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.isLoadingRestaurarModeloPadrao = true;
                this.content = [];
                await companyConfiguracaoStore.restaurarContratoPadrao();
                await companyConfiguracaoStore.getCompanyConfiguracoes();
                // @ts-ignores
                this.content = [...this.companyConfiguracoes.contrato];
                this.isLoadingRestaurarModeloPadrao = false;
            }
        });
    }
    appendParcelamentoMaterial() {
        const temp = [];
        _.forEach(this.companyConfiguracaoMaterialParcelamento, item => {
            if (item.edit) {
                item.edit = false;
            }
            temp.push({ ...item });
        });
        this.tempMaterialParcelamento = temp;
        this.companyConfiguracaoMaterialParcelamento.push({
            id: '',
            numero_vezes: '',
            valor_integral: 0,
            edit: true
        });
        this.newMaterialParcelamento = true;
    }
    saveParcelamentoMaterial() {
        if (this.camposInvalidadosMaterialDidaticoValoresReajustesParcelamentos()) {
            return false;
        }
        if (this.newMaterialParcelamento) {
            this.newMaterialParcelamento = false;
        }
        if (this.allowMaterialParcelamentoEdit) {
            this.allowMaterialParcelamentoEdit = false;
        }
        _.forEach(this.companyConfiguracaoMaterialParcelamento, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    async saveParcelamentoMaterialReajuste() {
        this.saveParcelamentoMaterial();
        await companyConfiguracaoMaterialParcelamentoStore.updateCompanyConfiguracaoMaterialParcelamentos({ ...this.companyConfiguracaoMaterialParcelamento });
    }
    toggleAllowMaterialParcelamentoEdit() {
        this.allowMaterialParcelamentoEdit = !this.allowMaterialParcelamentoEdit;
        const temp = [];
        _.forEach(this.companyConfiguracaoMaterialParcelamento, item => {
            if (item.edit) {
                item.edit = false;
            }
            temp.push({ ...item });
        });
        this.tempMaterialParcelamento = temp;
    }
    removeMateriaParcelamento(valueToRemove) {
        const payload = this.companyConfiguracaoMaterialParcelamento.filter(item => item !== valueToRemove);
        companyConfiguracaoMaterialParcelamentoStore.setCompanyConfiguracaoMaterialParcelamentos(payload);
        this.newMaterialParcelamento = false;
    }
    cancelMaterialParcelamento() {
        if (this.tempMaterialParcelamento) {
            companyConfiguracaoMaterialParcelamentoStore.setCompanyConfiguracaoMaterialParcelamentos(this.tempMaterialParcelamento);
        }
        this.allowMaterialParcelamentoEdit = false;
        this.newMaterialParcelamento = false;
    }
    // --------------------------
    appendNegociacao() {
        const temp = [];
        _.forEach(this.companyConfiguracaoNegociacoes, item => {
            temp.push({ ...item });
        });
        this.tempNegociacao = temp;
        this.companyConfiguracaoNegociacoes.push({
            numero_vezes: 0,
            desconto_percentual: 0,
            desconto_valor: 0,
            edit: true
        });
        this.newNegociacao = true;
    }
    saveNegociacao() {
        if (this.newNegociacao) {
            this.newNegociacao = false;
        }
        if (this.allowNegociacaoEdit) {
            this.allowNegociacaoEdit = false;
        }
        _.forEach(this.companyConfiguracaoNegociacoes, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleAllowNegociacaoEdit() {
        this.allowNegociacaoEdit = !this.allowNegociacaoEdit;
        const temp = [];
        _.forEach(this.companyConfiguracaoNegociacoes, item => {
            temp.push({ ...item });
        });
        this.tempNegociacao = temp;
    }
    removeNegociacao(valueToRemove) {
        const payload = this.companyConfiguracaoNegociacoes.filter(item => item !== valueToRemove);
        companyConfiguracaoNegociacaoStore.setCompanyConfiguracaoNegociacoes(payload);
        this.newNegociacao = false;
    }
    cancelNegociacao() {
        if (this.tempNegociacao) {
            companyConfiguracaoNegociacaoStore.setCompanyConfiguracaoNegociacoes(this.tempNegociacao);
        }
        this.allowNegociacaoEdit = false;
        this.newNegociacao = false;
    }
    // ---------------------------
    appendNegociacaoMultaRescisoria() {
        const temp = [];
        _.forEach(this.companyConfiguracaoNegociacoesMultaRescisoria, item => {
            temp.push({ ...item });
        });
        this.tempNegociacaoMultaRescisoria = temp;
        this.companyConfiguracaoNegociacoesMultaRescisoria.push({
            numero_vezes: 0,
            desconto_percentual: 0,
            desconto_valor: 0,
            edit: true
        });
        this.newNegociacaoMultaRescisoria = true;
    }
    async saveNegociacaoMultaRescisoria() {
        if (this.camposInvalidadosNegociacaoRescisaoContratual()) {
            return;
        }
        if (this.newNegociacaoMultaRescisoria) {
            this.newNegociacaoMultaRescisoria = false;
        }
        if (this.allowNegociacaoMultaRescisoriaEdit) {
            this.allowNegociacaoMultaRescisoriaEdit = false;
        }
        _.forEach(this.companyConfiguracaoNegociacoesMultaRescisoria, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleAllowNegociacaoMultaRescisoriaEdit() {
        this.allowNegociacaoMultaRescisoriaEdit = !this
            .allowNegociacaoMultaRescisoriaEdit;
        const temp = [];
        _.forEach(this.companyConfiguracaoNegociacoesMultaRescisoria, item => {
            temp.push({ ...item });
        });
        this.tempNegociacaoMultaRescisoria = temp;
    }
    removeNegociacaoMultaRescisoria(valueToRemove) {
        const payload = this.companyConfiguracaoNegociacoesMultaRescisoria.filter(item => item !== valueToRemove);
        companyConfiguracaoNegociacaoMultaRescisoriaContratoStore.setCompanyConfiguracaoNegociacoesMultaRescisoriaContrato(payload);
        this.newNegociacaoMultaRescisoria = false;
    }
    cancelNegociacaoMultaRescisoria() {
        if (this.tempNegociacaoMultaRescisoria) {
            companyConfiguracaoNegociacaoMultaRescisoriaContratoStore.setCompanyConfiguracaoNegociacoesMultaRescisoriaContrato(this.tempNegociacaoMultaRescisoria);
        }
        this.allowNegociacaoMultaRescisoriaEdit = false;
        this.newNegociacaoMultaRescisoria = false;
    }
    // ----------------------------
    appendCursoDesconto() {
        const temp = [];
        _.forEach(this.companyConfiguracaoCursoDesconto, item => {
            temp.push({ ...item });
        });
        this.tempCursoDesconto = temp;
        this.companyConfiguracaoCursoDesconto.push({
            percentual: 0,
            id: '',
            edit: true
        });
        this.newCursoDesconto = true;
    }
    saveCursoDesconto() {
        if (this.newCursoDesconto) {
            this.newCursoDesconto = false;
        }
        if (this.allowCursoDescontoEdit) {
            this.allowCursoDescontoEdit = false;
        }
        _.forEach(this.companyConfiguracaoCursoDesconto, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleAllowCursoDescontoEdit() {
        this.allowCursoDescontoEdit = !this.allowCursoDescontoEdit;
        const temp = [];
        _.forEach(this.companyConfiguracaoCursoDesconto, item => {
            temp.push({ ...item });
        });
        this.tempCursoDesconto = temp;
    }
    removeCursoDesconto(valueToRemove) {
        const payload = this.companyConfiguracaoCursoDesconto.filter(item => item !== valueToRemove);
        companyConfiguracaoCursoDescontoStore.setCompanyConfiguracaoCursoDescontos(payload);
        this.newCursoDesconto = false;
    }
    cancelCursoDesconto() {
        if (this.tempCursoDesconto) {
            companyConfiguracaoCursoDescontoStore.setCompanyConfiguracaoCursoDescontos(this.tempCursoDesconto);
        }
        this.allowCursoDescontoEdit = false;
        this.newCursoDesconto = false;
    }
    // -----------------------
    addRecebimentoTaxaAvulsa() {
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto103, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoTaxaAvulsa = temp;
        if (!this.financeiroCategoriasJurosDesconto103) {
            this.financeiroCategoriasJurosDesconto103 = [];
        }
        this.financeiroCategoriasJurosDesconto103.push({
            edit: true,
            // @ts-ignore
            financeiro_categoria_id: this.getFinanceiroCategoriaTaxaAvulsa103()
        });
        this.newRecebimentoTaxaAvulsa = true;
    }
    camposInvalidadosMaterialDidaticoValoresReajustesParcelamentos() {
        for (const item of this.companyConfiguracaoMaterialParcelamento) {
            // @ts-ignore
            if (item.numero_vezes < 0) {
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: [
                        `Quantidade de parcelas do Material Didático não pode ser negativo.`
                    ]
                });
                return true;
            }
        }
        return false;
    }
    saveRecebimentoTaxaAvulsa() {
        if (this.camposInvalidadosRecebimentoTaxaAvulsa()) {
            return;
        }
        if (this.newRecebimentoTaxaAvulsa) {
            this.newRecebimentoTaxaAvulsa = false;
        }
        if (this.allowRecebimentoTaxaAvulsaEdit) {
            this.allowRecebimentoTaxaAvulsaEdit = false;
        }
        _.forEach(this.financeiroCategoriasJurosDesconto103, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleRecebimentoTaxaAvulsaEdit() {
        this.allowRecebimentoTaxaAvulsaEdit = !this.allowRecebimentoTaxaAvulsaEdit;
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto103, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoTaxaAvulsa = temp;
    }
    removeRecebimentoTaxaAvulsa(valueToRemove) {
        const payload = this.financeiroCategoriasJurosDesconto103.filter(item => item !== valueToRemove);
        this.financeiroCategoriasJurosDesconto103 = payload;
        this.newRecebimentoTaxaAvulsa = false;
    }
    cancelRecebimentoTaxaAvulsa() {
        if (this.tempRecebimentoTaxaAvulsa) {
            this.financeiroCategoriasJurosDesconto103 = this.tempRecebimentoTaxaAvulsa;
        }
        this.allowRecebimentoTaxaAvulsaEdit = false;
        this.newRecebimentoTaxaAvulsa = false;
    }
    // -----------------------
    addRecebimentoMaterialDidatico() {
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto104, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoMaterialDidatico = temp;
        if (!this.financeiroCategoriasJurosDesconto104) {
            this.financeiroCategoriasJurosDesconto104 = [];
        }
        this.financeiroCategoriasJurosDesconto104.push({
            edit: true,
            // @ts-ignore
            financeiro_categoria_id: this.getFinanceiroCategoriaMaterialDidatico104()
        });
        this.newRecebimentoMaterialDidatico = true;
    }
    saveRecebimentoMaterialDidatico() {
        if (this.camposInvalidadosRecebimentoMaterialDidatico()) {
            return;
        }
        if (this.newRecebimentoMaterialDidatico) {
            this.newRecebimentoMaterialDidatico = false;
        }
        if (this.allowRecebimentoMaterialDidaticoEdit) {
            this.allowRecebimentoMaterialDidaticoEdit = false;
        }
        _.forEach(this.financeiroCategoriasJurosDesconto104, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleRecebimentoMaterialDidaticoEdit() {
        this.allowRecebimentoMaterialDidaticoEdit = !this
            .allowRecebimentoMaterialDidaticoEdit;
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto104, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoMaterialDidatico = temp;
    }
    removeRecebimentoMaterialDidatico(valueToRemove) {
        const payload = this.financeiroCategoriasJurosDesconto104.filter(item => item !== valueToRemove);
        this.financeiroCategoriasJurosDesconto104 = payload;
        this.newRecebimentoMaterialDidatico = false;
    }
    cancelRecebimentoMaterialDidatico() {
        if (this.tempRecebimentoMaterialDidatico) {
            this.financeiroCategoriasJurosDesconto104 = this.tempRecebimentoMaterialDidatico;
        }
        this.allowRecebimentoMaterialDidaticoEdit = false;
        this.newRecebimentoMaterialDidatico = false;
    }
    // -----------------------
    // -----------------------
    addRecebimentoParcelaCurso() {
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto105, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoParcelaCurso = temp;
        if (!this.financeiroCategoriasJurosDesconto105) {
            this.financeiroCategoriasJurosDesconto105 = [];
        }
        this.financeiroCategoriasJurosDesconto105.push({
            edit: true,
            // @ts-ignore
            financeiro_categoria_id: this.getFinanceiroCategoriaCurso105()
        });
        this.newRecebimentoParcelaCurso = true;
    }
    saveRecebimentoParcelaCurso() {
        if (this.camposInvalidadosRecebimentoParcelaCurso()) {
            return;
        }
        if (this.newRecebimentoParcelaCurso) {
            this.newRecebimentoParcelaCurso = false;
        }
        if (this.allowRecebimentoParcelaCursoEdit) {
            this.allowRecebimentoParcelaCursoEdit = false;
        }
        _.forEach(this.financeiroCategoriasJurosDesconto105, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleRecebimentoParcelaCursoEdit() {
        this.allowRecebimentoParcelaCursoEdit = !this
            .allowRecebimentoParcelaCursoEdit;
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto105, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoParcelaCurso = temp;
    }
    removeRecebimentoParcelaCurso(valueToRemove) {
        const payload = this.financeiroCategoriasJurosDesconto105.filter(item => item !== valueToRemove);
        this.financeiroCategoriasJurosDesconto105 = payload;
        this.newRecebimentoParcelaCurso = false;
    }
    cancelRecebimentoParcelaCurso() {
        if (this.tempRecebimentoParcelaCurso) {
            this.financeiroCategoriasJurosDesconto105 = this.tempRecebimentoParcelaCurso;
        }
        this.allowRecebimentoParcelaCursoEdit = false;
        this.newRecebimentoParcelaCurso = false;
    }
    // -----------------------
    // -----------------------
    addRecebimentoTaxaReforco() {
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto106, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoTaxaReforco = temp;
        if (!this.financeiroCategoriasJurosDesconto106) {
            this.financeiroCategoriasJurosDesconto106 = [];
        }
        this.financeiroCategoriasJurosDesconto106.push({
            edit: true,
            // @ts-ignore
            financeiro_categoria_id: this.getFinanceiroCategoriaTaxaReforco106()
        });
        this.newRecebimentoTaxaReforco = true;
    }
    saveRecebimentoTaxaReforco() {
        if (this.camposInvalidadosRecebimentoTaxaReforcoAulaExtra()) {
            return;
        }
        if (this.newRecebimentoTaxaReforco) {
            this.newRecebimentoTaxaReforco = false;
        }
        if (this.allowRecebimentoTaxaReforcoEdit) {
            this.allowRecebimentoTaxaReforcoEdit = false;
        }
        _.forEach(this.financeiroCategoriasJurosDesconto106, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleRecebimentoTaxaReforcoEdit() {
        this.allowRecebimentoTaxaReforcoEdit = !this
            .allowRecebimentoTaxaReforcoEdit;
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto106, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoTaxaReforco = temp;
    }
    removeRecebimentoTaxaReforco(valueToRemove) {
        const payload = this.financeiroCategoriasJurosDesconto106.filter(item => item !== valueToRemove);
        this.financeiroCategoriasJurosDesconto106 = payload;
        this.newRecebimentoTaxaReforco = false;
    }
    cancelRecebimentoTaxaReforco() {
        if (this.tempRecebimentoTaxaReforco) {
            this.financeiroCategoriasJurosDesconto106 = this.tempRecebimentoTaxaReforco;
        }
        this.allowRecebimentoTaxaReforcoEdit = false;
        this.newRecebimentoTaxaReforco = false;
    }
    // -----------------------
    // -----------------------
    addRecebimentoTaxaReposicao() {
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto107, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoTaxaReposicao = temp;
        if (!this.financeiroCategoriasJurosDesconto107) {
            this.financeiroCategoriasJurosDesconto107 = [];
        }
        this.financeiroCategoriasJurosDesconto107.push({
            edit: true,
            financeiro_categoria_id: 
            // @ts-ignore
            this.getFinanceiroCategoriaTaxaReposicaoAula107()
        });
        this.newRecebimentoTaxaReposicao = true;
    }
    saveRecebimentoTaxaReposicao() {
        if (this.camposInvalidadosRecebimentoTaxaReposicaoAula()) {
            return;
        }
        if (this.newRecebimentoTaxaReposicao) {
            this.newRecebimentoTaxaReposicao = false;
        }
        if (this.allowRecebimentoTaxaReposicaoEdit) {
            this.allowRecebimentoTaxaReposicaoEdit = false;
        }
        _.forEach(this.financeiroCategoriasJurosDesconto107, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleRecebimentoTaxaReposicaoEdit() {
        this.allowRecebimentoTaxaReposicaoEdit = !this
            .allowRecebimentoTaxaReposicaoEdit;
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto107, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoTaxaReposicao = temp;
    }
    removeRecebimentoTaxaReposicao(valueToRemove) {
        const payload = this.financeiroCategoriasJurosDesconto107.filter(item => item !== valueToRemove);
        this.financeiroCategoriasJurosDesconto107 = payload;
        this.newRecebimentoTaxaReposicao = false;
    }
    cancelRecebimentoTaxaReposicao() {
        if (this.tempRecebimentoTaxaReposicao) {
            this.financeiroCategoriasJurosDesconto107 = this.tempRecebimentoTaxaReposicao;
        }
        this.allowRecebimentoTaxaReposicaoEdit = false;
        this.newRecebimentoTaxaReposicao = false;
    }
    // -----------------------
    // -----------------------
    addRecebimentoTaxaMatricula() {
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto108, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoTaxaMatricula = temp;
        if (!this.financeiroCategoriasJurosDesconto108) {
            this.financeiroCategoriasJurosDesconto108 = [];
        }
        this.financeiroCategoriasJurosDesconto108.push({
            edit: true,
            // @ts-ignore
            financeiro_categoria_id: this.getFinanceiroCategoriaTaxaMatricula108()
        });
        this.newRecebimentoTaxaMatricula = true;
    }
    saveRecebimentoTaxaMatricula() {
        if (this.camposInvalidadosRecebimentoTaxaMatricula()) {
            return;
        }
        if (this.newRecebimentoTaxaMatricula) {
            this.newRecebimentoTaxaMatricula = false;
        }
        if (this.allowRecebimentoTaxaMatriculaEdit) {
            this.allowRecebimentoTaxaMatriculaEdit = false;
        }
        _.forEach(this.financeiroCategoriasJurosDesconto108, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleRecebimentoTaxaMatriculaEdit() {
        this.allowRecebimentoTaxaMatriculaEdit = !this
            .allowRecebimentoTaxaMatriculaEdit;
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto108, item => {
            temp.push({ ...item });
        });
        this.tempRecebimentoTaxaMatricula = temp;
    }
    removeRecebimentoTaxaMatricula(valueToRemove) {
        const payload = this.financeiroCategoriasJurosDesconto108.filter(item => item !== valueToRemove);
        this.financeiroCategoriasJurosDesconto108 = payload;
        this.newRecebimentoTaxaMatricula = false;
    }
    cancelRecebimentoTaxaMatricula() {
        if (this.tempRecebimentoTaxaMatricula) {
            this.financeiroCategoriasJurosDesconto108 = this.tempRecebimentoTaxaMatricula;
        }
        this.allowRecebimentoTaxaMatriculaEdit = false;
        this.newRecebimentoTaxaMatricula = false;
    }
    // -----------------------
    // -----------------------
    addMultaCancelamentoContrato() {
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto109, item => {
            temp.push({ ...item });
        });
        this.tempMultaCancelamentoContrato = temp;
        if (!this.financeiroCategoriasJurosDesconto109) {
            this.financeiroCategoriasJurosDesconto109 = [];
        }
        this.financeiroCategoriasJurosDesconto109.push({
            edit: true,
            financeiro_categoria_id: 
            // @ts-ignore
            this.getFinanceiroCategoriaRescisaoContratual109()
        });
        this.newMultaCancelamentoContrato = true;
    }
    saveMultaCancelamentoContrato() {
        if (!this.validarPeriodoDiasValidos('Negociação de Rescisão Contratual', this.financeiroCategoriasJurosDesconto109)) {
            return;
        }
        if (this.newMultaCancelamentoContrato) {
            this.newMultaCancelamentoContrato = false;
        }
        if (this.allowMultaCancelamentoContratoEdit) {
            this.allowMultaCancelamentoContratoEdit = false;
        }
        _.forEach(this.financeiroCategoriasJurosDesconto109, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleMultaCancelamentoContratoEdit() {
        this.allowMultaCancelamentoContratoEdit = !this
            .allowMultaCancelamentoContratoEdit;
        const temp = [];
        _.forEach(this.financeiroCategoriasJurosDesconto109, item => {
            temp.push({ ...item });
        });
        this.tempMultaCancelamentoContrato = temp;
    }
    removeMultaCancelamentoContrato(valueToRemove) {
        const payload = this.financeiroCategoriasJurosDesconto109.filter(item => item !== valueToRemove);
        this.financeiroCategoriasJurosDesconto109 = payload;
        this.newMultaCancelamentoContrato = false;
    }
    cancelMultaCancelamentoContrato() {
        if (this.tempMultaCancelamentoContrato) {
            this.financeiroCategoriasJurosDesconto109 = this.tempMultaCancelamentoContrato;
        }
        this.allowMultaCancelamentoContratoEdit = false;
        this.newMultaCancelamentoContrato = false;
    }
    // -----------------------
    // -----------------------
    addDiaVencimentoParcelas() {
        const temp = [];
        _.forEach(this.companyConfiguracaoDiaVencimentoParcelas, item => {
            temp.push({ ...item });
        });
        this.tempDiaVencimentoParcelas = temp;
        if (!this.companyConfiguracaoDiaVencimentoParcelas) {
            companyConfiguracaoDiaVencimentoParcelaStore.setCompanyConfiguracaoDiaVencimentoParcela([]);
        }
        this.companyConfiguracaoDiaVencimentoParcelas.push({
            edit: true,
            id: 0,
            dia: 0
        });
        this.newDiaVencimentoParcelas = true;
    }
    saveDiaVencimentoParcelas() {
        if (this.newDiaVencimentoParcelas) {
            this.newDiaVencimentoParcelas = false;
        }
        if (this.allowDiaVencimentoParcelasEdit) {
            this.allowDiaVencimentoParcelasEdit = false;
        }
        _.forEach(this.companyConfiguracaoDiaVencimentoParcelas, item => {
            if (item.edit) {
                item.edit = false;
            }
        });
    }
    toggleAllowDiaVencimentoParcelasEdit() {
        this.allowDiaVencimentoParcelasEdit = !this.allowDiaVencimentoParcelasEdit;
        const temp = [];
        _.forEach(this.companyConfiguracaoDiaVencimentoParcelas, item => {
            temp.push({ ...item });
        });
        this.tempDiaVencimentoParcelas = temp;
    }
    removeDiaVencimentoParcelas(valueToRemove) {
        const payload = this.companyConfiguracaoDiaVencimentoParcelas.filter(item => item !== valueToRemove);
        companyConfiguracaoDiaVencimentoParcelaStore.setCompanyConfiguracaoDiaVencimentoParcela(payload);
        this.newDiaVencimentoParcelas = false;
    }
    cancelDiaVencimentoParcelas() {
        if (this.tempDiaVencimentoParcelas) {
            companyConfiguracaoDiaVencimentoParcelaStore.setCompanyConfiguracaoDiaVencimentoParcela(this.tempDiaVencimentoParcelas);
        }
        this.allowDiaVencimentoParcelasEdit = false;
        this.newDiaVencimentoParcelas = false;
    }
    // -----------------------
    BrToDatetime(item) {
        if (item.vigencia_inicio) {
            const dataFormatadaInicio = item.vigencia_inicio.split('/');
            const dateInicio = dataFormatadaInicio[2] +
                '-' +
                dataFormatadaInicio[1] +
                '-' +
                dataFormatadaInicio[0];
            item.vigencia_inicio = dateInicio;
        }
        else {
            item.vigencia_inicio = null;
        }
        if (item.vigencia_fim) {
            const dataFormatadaFim = item.vigencia_fim.split('/');
            const dateFim = dataFormatadaFim[2] +
                '-' +
                dataFormatadaFim[1] +
                '-' +
                dataFormatadaFim[0];
            item.vigencia_fim = dateFim;
        }
        else {
            item.vigencia_fim = null;
        }
    }
    DatetimeToBr(item) {
        if (item.vigencia_inicio) {
            const dataFormatadaInicio = item.vigencia_inicio.split('-');
            const dateInicio = dataFormatadaInicio[2] +
                '/' +
                dataFormatadaInicio[1] +
                '/' +
                dataFormatadaInicio[0];
            item.vigencia_inicio = dateInicio;
        }
        if (item.vigencia_fim) {
            const dataFormatadaFim = item.vigencia_fim.split('-');
            const dateFim = dataFormatadaFim[2] +
                '/' +
                dataFormatadaFim[1] +
                '/' +
                dataFormatadaFim[0];
            item.vigencia_fim = dateFim;
        }
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    validarPeriodoDiasValidos(title, arr) {
        if (!(arr?.length > 0)) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: [
                    `${title}, está vazio, por favor preencha com a informação da sua escola.`
                ]
            });
            return false;
        }
        for (const item of arr) {
            for (const item2 of arr) {
                if (item.id === item2.id) {
                    continue;
                }
                if ((Number(item2.desconto_perda_quantidade_dia_vencido_inicio) >=
                    Number(item.desconto_perda_quantidade_dia_vencido_inicio) &&
                    Number(item2.desconto_perda_quantidade_dia_vencido_inicio) <=
                        Number(item.desconto_perda_quantidade_dia_vencido_fim)) ||
                    (Number(item2.desconto_perda_quantidade_dia_vencido_fim) >=
                        Number(item.desconto_perda_quantidade_dia_vencido_inicio) &&
                        Number(item2.desconto_perda_quantidade_dia_vencido_fim) <=
                            Number(item.desconto_perda_quantidade_dia_vencido_fim))) {
                    mainStore.addErrorToasted({
                        // @ts-ignore
                        toasted: this.$toasted,
                        errors: [`${title}, esta com conflito de dias.`]
                    });
                    return false;
                }
            }
        }
        return true;
    }
    camposInvalidadosRecebimentoTaxaAvulsa() {
        return !this.validarPeriodoDiasValidos('Recebimento de Taxa Avulsa', this.financeiroCategoriasJurosDesconto103);
    }
    camposInvalidadosRecebimentoMaterialDidatico() {
        return !this.validarPeriodoDiasValidos('Recebimento de Material Didático', this.financeiroCategoriasJurosDesconto104);
    }
    camposInvalidadosRecebimentoParcelaCurso() {
        return !this.validarPeriodoDiasValidos('Recebimento de Parcela Curso', this.financeiroCategoriasJurosDesconto105);
    }
    camposInvalidadosRecebimentoTaxaReforcoAulaExtra() {
        return !this.validarPeriodoDiasValidos('Recebimento de Taxa de Reforço', this.financeiroCategoriasJurosDesconto106);
    }
    camposInvalidadosRecebimentoTaxaReposicaoAula() {
        return !this.validarPeriodoDiasValidos('Recebimento de Taxa de Reposição de aula', this.financeiroCategoriasJurosDesconto107);
    }
    camposInvalidadosRecebimentoTaxaMatricula() {
        return !this.validarPeriodoDiasValidos('Recebimento de Taxa de Matrícula', this.financeiroCategoriasJurosDesconto108);
    }
    camposInvalidadosNegociacaoRescisaoContratual() {
        let erroCampos = false;
        for (const item of this.companyConfiguracaoNegociacoesMultaRescisoria) {
            if (item.numero_vezes <= 0) {
                erroCampos = true;
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: ['Campo Quantidade de parcelas deve ser maior que zero']
                });
                break;
            }
        }
        if (erroCampos) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return true;
        }
        return false;
    }
    camposValidos() {
        if (this.camposInvalidadosMaterialDidaticoValoresReajustesParcelamentos() ||
            this.camposInvalidadosRecebimentoTaxaAvulsa() ||
            this.camposInvalidadosRecebimentoMaterialDidatico() ||
            this.camposInvalidadosRecebimentoParcelaCurso() ||
            this.camposInvalidadosRecebimentoTaxaReforcoAulaExtra() ||
            this.camposInvalidadosRecebimentoTaxaReposicaoAula() ||
            this.camposInvalidadosRecebimentoTaxaMatricula() ||
            this.camposInvalidadosNegociacaoRescisaoContratual()) {
            return false;
        }
        return true;
    }
    async submit() {
        if (!this.camposValidos()) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaTaxaAvulsa103()] = this.financeiroCategoriasJurosDesconto103;
        this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaMaterialDidatico104()] = this.financeiroCategoriasJurosDesconto104;
        this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaCurso105()] = this.financeiroCategoriasJurosDesconto105;
        this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaTaxaReforco106()] = this.financeiroCategoriasJurosDesconto106;
        this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaTaxaReposicaoAula107()] = this.financeiroCategoriasJurosDesconto107;
        this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaTaxaMatricula108()] = this.financeiroCategoriasJurosDesconto108;
        this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaRescisaoContratual109()] = this.financeiroCategoriasJurosDesconto109;
        _.forEach(this.financeiroCategoriasJurosDesconto, categoria => {
            _.forEach(categoria, item => {
                this.BrToDatetime(item);
            });
        });
        await companyConfiguracaoDiaVencimentoParcelaStore.updateCompanyConfiguracaoDiaVencimentoParcelas(this.companyConfiguracaoDiaVencimentoParcelas);
        await financeiroCategoriaJurosDescontoStore.updateFinanceiroCategoriasJurosDescontoStore(this.financeiroCategoriasJurosDesconto);
        await companyConfiguracaoNegociacaoStore.updateCompanyConfiguracaoNegociacoes({
            ...this.companyConfiguracaoNegociacoes
        });
        await companyConfiguracaoNegociacaoMultaRescisoriaContratoStore.updateCompanyConfiguracaoNegociacoesMultaRescisoria({
            ...this.companyConfiguracaoNegociacoesMultaRescisoria
        });
        await companyConfiguracaoCursoDescontoStore.updateCompanyConfiguracaoCursoDescontos({ ...this.companyConfiguracaoCursoDesconto });
        await companyConfiguracaoMaterialParcelamentoStore.updateCompanyConfiguracaoMaterialParcelamentos({ ...this.companyConfiguracaoMaterialParcelamento });
        await companyConfiguracaoStore.updateCompanyConfiguracoes(this.entityObject);
        _.forEach(this.financeiroCategoriasJurosDesconto103, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto104, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto105, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto106, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto107, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto108, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto109, item => {
            this.DatetimeToBr(item);
        });
        // @ts-ignore
        this.$refs.saveButton.changeLoadingStatus(false);
    }
    async downloadTemplate() {
        let source = '';
        let templateName = '';
        switch (this.templateDownload) {
            case 'Alunos':
                source = await alunoStore.getAlunosTableColunas();
                templateName = 'alunos_template';
        }
        const blob = new Blob([source], { type: 'application/xlsx' });
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', `${templateName}.xlsx`);
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setTimeout(() => {
            window.URL.revokeObjectURL(blobURL);
        }, 100);
    }
    async importTemplateData() {
        switch (this.templateImportacao) {
            case 'Alunos':
                const response = await alunoStore.importAlunosTemplateData([
                    ...this.verifyImportItems
                ]);
        }
    }
    async previewFileData() {
        const formData = new FormData();
        // @ts-ignore
        formData.append('file', this.importFile);
        switch (this.templateImportacao) {
            case 'Alunos':
                const { data, error, header } = await alunoStore.previewFileAlunosData(formData);
                this.verifyImportItems = data;
                this.importHaveErrors = error;
                this.importFileHeader = header;
                break;
        }
    }
    async routeContrato() {
        await this.$router.push({ name: 'main-configuracoes-contrato' });
    }
    get financeiroCategoriasJurosDesconto() {
        return _.groupBy(financeiroCategoriaJurosDescontoStore.financeiroCategoriasJurosDesconto, 'financeiro_categoria_id');
    }
    async restaurarPadrao(tipo) {
        this.isLoadingRestaurarPadrao = true;
        await companyConfiguracaoStore.companyConfiguracaoRestaurarPadrao(tipo);
        this.isLoadingRestaurarPadrao = false;
    }
    async restaurarPadraoDescontoCurso() {
        this.isLoadingRestaurarPadrao = true;
        await companyConfiguracaoCursoDescontoStore.companyConfiguracaoCursoDescontoRestaurarPadrao('desconto_curso');
        this.isLoadingRestaurarPadrao = false;
    }
    async restaurarPadraoDiaVencimentoParcela() {
        this.isLoadingRestaurarPadrao = true;
        await companyConfiguracaoDiaVencimentoParcelaStore.companyConfiguracaoDiaVencimentoParcelaRestaurarPadrao('dia_vencimento_parcelas_curso_material');
        this.isLoadingRestaurarPadrao = false;
    }
    async restaurarPadraoMaterialDidaticoValoresReajustesParcelamento() {
        this.isLoadingRestaurarPadrao = true;
        await companyConfiguracaoMaterialParcelamentoStore.companyConfiguracaoMaterialParcelamentoRestaurarPadrao('material_valores_reajustes_parcelamentos');
        this.isLoadingRestaurarPadrao = false;
    }
    async restaurarPadraoNegociacaoRescisaoContratual() {
        this.isLoadingRestaurarPadrao = true;
        await companyConfiguracaoNegociacaoMultaRescisoriaContratoStore.companyConfiguracaoNegociacaoMultaRescisaoContratoRestaurarPadrao('negociacao_rescisao_contratual');
        this.isLoadingRestaurarPadrao = false;
    }
    async restaurarPadraoNegociacaoTitulos() {
        this.isLoadingRestaurarPadrao = true;
        await companyConfiguracaoNegociacaoStore.companyConfiguracaoNegociacaoRestaurarPadrao('negociacao_titulos');
        this.isLoadingRestaurarPadrao = false;
    }
    async mounted() {
        this.loading = true;
        await companyConfiguracaoStore.getCompanyConfiguracoes();
        await companyConfiguracaoDiaVencimentoParcelaStore.getCompanyConfiguracaoDiaVencimentoParcelas();
        await companyConfiguracaoNegociacaoMultaRescisoriaContratoStore.getCompanyConfiguracaoNegociacoesMultaRescisoriaContrato();
        await companyConfiguracaoNegociacaoStore.getCompanyConfiguracaoNegociacoes();
        await companyConfiguracaoMaterialParcelamentoStore.getCompanyConfiguracaoMaterialParcelamentos();
        await companyConfiguracaoCursoDescontoStore.getCompanyConfiguracaoCursoDescontos();
        await financeiroCategoriaJurosDescontoStore.getFinanceiroCategoriasJurosDescontoStore();
        this.financeiroCategoriasJurosDesconto103 = this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaTaxaAvulsa103()];
        this.financeiroCategoriasJurosDesconto104 = this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaMaterialDidatico104()];
        this.financeiroCategoriasJurosDesconto105 = this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaCurso105()];
        this.financeiroCategoriasJurosDesconto106 = this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaTaxaReforco106()];
        this.financeiroCategoriasJurosDesconto107 = this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaTaxaReposicaoAula107()];
        this.financeiroCategoriasJurosDesconto108 = this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaTaxaMatricula108()];
        this.financeiroCategoriasJurosDesconto109 = this.financeiroCategoriasJurosDesconto[
        // @ts-ignore
        this.getFinanceiroCategoriaRescisaoContratual109()];
        _.forEach(this.financeiroCategoriasJurosDesconto103, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto104, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto105, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto106, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto107, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto108, item => {
            this.DatetimeToBr(item);
        });
        _.forEach(this.financeiroCategoriasJurosDesconto109, item => {
            this.DatetimeToBr(item);
        });
        // @ts-ignore
        if (this.entityObject.rescisao_quantidade_meses_aplicar_multa === 0) {
            this.aplicarMultaAteFinalContrato = true;
        }
        // @ts-ignore
        if (this.companyConfiguracoes.contrato) {
            // @ts-ignore
            this.content = this.companyConfiguracoes.contrato;
        }
        this.loading = false;
    }
    async tabDadosUnidadeMounted() {
        this.tabDadosUnidadeData = await companyStore.getCompanyDadosUnidade(this.userProfile.company.id);
    }
    get companyConfiguracoes() {
        return companyConfiguracaoStore.companyConfiguracoes;
    }
};
__decorate([
    Watch('aplicarMultaAteFinalContrato')
], List.prototype, "onAplicarMultaAteFinalContrato", null);
__decorate([
    Watch('entityObject.rescisao_quantidade_meses_aplicar_multa')
], List.prototype, "onRescisao_quantidade_meses_aplicar_multa", null);
List = __decorate([
    Component({
        components: {
            ReajustarMaterialParcelamentoComponent,
            TabDrivePay,
            SaveButton,
            DocumentEditorComponent,
            LogShowComponent,
            FinanceiroCategoria,
            ValidationObserver,
            ValidationProvider
        }
    })
], List);
export default List;
